let Material = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取中药搜索列表数据
     * @param {any} success
     * @param {any} error
     */
    this.Materials = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Material/Search' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
  * 获取中药热词数据
  * @param {any} success
  * @param {any} error
  */
    this.Hotspot = function (success, error) {
        var url = ServiceBaseAddress + '/api/Material/Hotspot';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
 * 获取中药分类
 * @param {any} success
 * @param {any} error
 */
    this.Categorys = function (success, error) {
        var url = ServiceBaseAddress + '/api/Material/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取中药详情数据
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Material/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Material }